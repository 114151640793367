<section class="navbar">
  <ul>
    <li *ngFor="let navLink of navLinks" class="navbar__link" (click)="resetTrialBinders()">
      <a
        routerLink="{{ '/' + navLink.route }}"
        routerLinkActive="navbar__link-active"
      >
        <mat-icon
          class="navbar__link-icon"
          svgIcon="{{ navLink.svgIcon }}"
          matTooltip="{{ navLink.title }}"
          matTooltipPosition="right"
          matTooltipClass="navbar__tooltip"
        />
      </a>
    </li>
    <li class="navbar__link">
      <mat-icon
      class="navbar__link-icon || phone-icon"
      svgIcon="contact-phone"
      matTooltip="Contact Us"
      matTooltipPosition="right"
      matTooltipClass="navbar__tooltip" [matMenuTriggerFor]="contactMenu"/>
      <mat-menu #contactMenu="matMenu" xPosition="after">
        <button mat-menu-item><a id="LnkChatOnline" class="contact-menu-item" href="javascript:void zE('messenger', 'open');">Chat Online</a></button>
        <button mat-menu-item><a class="contact-menu-item" href="tel:1-888-529-7587">1-888-529-7587</a></button>
      </mat-menu>
    </li>
    <!--<li class="navbar__link">
      <app-auto-open-menu>
        <mat-icon trigger class="navbar__link-icon" svgIcon="settings" />
        <button content (click)="logout()">Log out</button>
      </app-auto-open-menu>
    </li>-->

  </ul>
  <!--<a href="tel:1-555-555-5555" class="navbar__link">
    <mat-icon
      class="navbar__link-icon || phone-icon"
      svgIcon="contact-phone"
      matTooltip="Contact Us"
      matTooltipPosition="right"
      matTooltipClass="navbar__tooltip"
    />
  </a>-->
  <a class="navbar--logo" [routerLink]="['/home']">
    <img
      alt="File and ServeXpress logo"
      src="../../../assets/icons/FSX-logo.png"
      style="width: 28px"
    />
  </a>
</section>
