import { Routes } from '@angular/router';
import {canActivateChild} from './app/core/auth/auth.guard';

export const APP_ROUTES: Routes = [
  {
    path: '',
    // ToDo: Disabled until I can correctly get the redirect to work.
    // canActivateChild: [canActivateChild],
    children: [
      {
        path: 'home',
        title: 'Home',
        loadComponent: () => import('./app/pages/home/home.component'),
        data: {
          breadcrumb: 'Home',
        }
      },
      {
        path: 'filing',
        loadComponent: () => import('./app/pages/filing/filing.component'),
        data: {
          breadcrumb: 'Filing',
        }
      },
      {
        path: 'case-management',
        title: 'Case & Party Management',
        data: {
          breadcrumb: 'Case & Party Management'
        },
        loadChildren: () => import('./app/pages/track-and-manage/case-management/case-management.routes'),
      },
      {
        path: 'trialbinder',
        title: 'Trial Binder',
        loadChildren: () => import('./app/trialbinder/trialbinder.routes'),
      },
      {
        path: 'messageboard',
        title: 'Message Boards',
        data: {
          breadcrumb: 'Message Boards',
        },
        children: [
          {
            path: '',
            loadComponent: () => import('./app/pages/messageboard/messageboard.component'),
            pathMatch: 'full',
            data: {
              breadcrumb: '',
            },
          },
          {
            path: 'subjects/:forumID',
            loadComponent: () => import('./app/pages/subjects/subjects.component'),
            data: {
              breadcrumb: 'Subjects',
            },
          },
        ],
      },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ]
  }
]
