import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { AppConfig } from './core/app-config/app-config.service';
import { FsxContact } from './types/user/User';
import { IconResolver, MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexModule } from '@angular/flex-layout';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {TrialBindersStateService} from './trialbinder/services/ui-state/trial-binders-state.service';
import {SnackbarComponent} from './trialbinder/shared/snackbar/snackbar.component';

export function loadConfig(
  appConfigService: AppConfig,
): () => Observable<string | Error> {
  return () => {
    return appConfigService.load$(environment.CONFIG_FILE);
  };
}
@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [CommonModule, RouterOutlet, NavbarComponent, MatSidenavModule, FlexModule, MatSnackBarModule],
})
export class AppComponent implements OnDestroy {
  public user$: Observable<FsxContact | null> = this.authService.sessionUser;
  private destroy$: Subject<unknown> = new Subject();
  currentDate = new Date();

  private isAuthenticated$: Observable<boolean> =
    this.authService.isAuthenticated.pipe(
      tap((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          this.authService.stopSessionChecks();
        }
      }),
    );

  public constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly snackBar: MatSnackBar,
    private readonly authService: AuthService,
    private readonly trialBinderStateService: TrialBindersStateService
  ) {
    // Subscribe to any error messages to show snack bar.
    this.trialBinderStateService.errorMessage$
      .pipe(
        takeUntil(this.destroy$),
        tap(error => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: [
              'tb-snackbar-error'
            ],
            data: {
              snackbar: this.snackBar,
              message: error,
            },
            duration: 3000
          });
        })
      )
      .subscribe()
    ;
    // ToDo: Uncomment this to test snackbar component.
    // this.snackBar.openFromComponent(SnackbarComponent, {
    //   horizontalPosition: 'center',
    //   verticalPosition: 'top',
    //   panelClass: [
    //     'tb-snackbar-error'
    //   ],
    //   data: {
    //     snackbar: this.snackBar,
    //     message: 'An error occurred.',
    //   }
    // });

    // mat icon registry
    // All SVG icons inside the /assets/icons/ folder can be used by file name. 
    // e.g., svgIcon="home" for /assets/icons/home.svg
    this.matIconRegistry
    .addSvgIconResolver((name) => this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/icons/${name}.svg`));

    this.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
