export interface ITrialBinderEndpoints {
  getFilingDetails: (getFilingDetails: string) => string;
  getTrialBinders: () => string;
  getTrialBinderFolders: (trialBinderId: string) => string;
  getTrialBinderFolderChildren: (trialBinderId: string, trialBinderFolderId: string) => string;
  getTrialBinderCaseDocuments: (trialBinderId: string) => string;
  getTrialBinderDocuments: (trialBinderId: string, trialBinderFolderId: string) => string;
  addDocumentToTrialBinder: (folderId: string, documentId: string, partyType: string, partyName: string) => string;
  addBookmark: (trialBinderId: string) => string;
  deleteBookmark: (trialBinderId: string) => string;
  zipTrialBinder: (trialBinderId: string) => string;
  getDocument: (transactionId: string) => string;
  archiveDocument: (trialBinderId: string) => string;
  moveDocument: (trialBinderId: string) => string;
  createNewFolder: (trialBinderId: string) => string;
  deleteFolder: (trialBinderId: string, trialBinderFolderId: string) => string;
  searchTrialBinder: (trialBinderId: string, searchText: string) => string;
}

export const trialBinderEndpoints = (versionedUrl: string): ITrialBinderEndpoints => {
  const baseUrl = `${versionedUrl}/TrialBinder`;

  return {
    getTrialBinders: () => `${baseUrl}/GetTrialBinders`,
    getTrialBinderFolders: (trialBinderId: string) => `${baseUrl}/GetTrialBinderFolders/${trialBinderId}`,
    getTrialBinderFolderChildren: (trialBinderId: string, trialBinderFolderId: string) => `${baseUrl}/GetTrialBinderFolderChildren/${trialBinderId}/${trialBinderFolderId}`,
    getTrialBinderCaseDocuments: (trialBinderId: string) => `${baseUrl}/GetTrialBinderCaseDocuments/${trialBinderId}`,
    getTrialBinderDocuments: (trialBinderId: string, trialBinderFolderId: string) => `${baseUrl}/GetTrialBinderDocuments/${trialBinderId}/${trialBinderFolderId}`,
    addDocumentToTrialBinder: (folderId: string, documentId: string, partyType: string, partyName: string) => `${baseUrl}/AddDocumentToTrialBinder/${folderId}/${documentId}/${partyType}/${partyName}`,
    addBookmark: (trialBinderId: string) => `${baseUrl}/AddBookmark/${trialBinderId}`,
    deleteBookmark: (trialBinderId: string) => `${baseUrl}/DeleteBookmark/${trialBinderId}`,
    zipTrialBinder: (trialBinderId: string) => `${baseUrl}/ZipTrialBinder/${trialBinderId}`,
    getDocument: (transactionId: string) => `${baseUrl}/GetDocument/${transactionId}`,
    archiveDocument: (trialBinderId: string) => `${baseUrl}/ArchiveDocument/${trialBinderId}`,
    moveDocument: (trialBinderId: string) => `${baseUrl}/MoveDocument/${trialBinderId}`,
    createNewFolder: (trialBinderId: string) => `${baseUrl}/CreateNewFolder/${trialBinderId}`,
    deleteFolder: (trialBinderId: string, trialBinderFolderId: string) => `${baseUrl}/DeleteFolder/${trialBinderId}/${trialBinderFolderId}`,
    getFilingDetails: (transactionId: string) => `${baseUrl}/GetFilingDetails/${transactionId}`,
    searchTrialBinder: (trialBinderId: string, searchText: string) => `${baseUrl}/SearchTrialBinderDocuments/${trialBinderId}/${searchText}`,
  }
}
